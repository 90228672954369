import React, {
    useState, useContext, useMemo,
    useCallback,
    useRef,
    useEffect,
} from 'react';
import useStyles from './UnitRateListView.style';
import {
    Box,
} from '@mui/material';
import {
    CellRendererDetailsType, CellRendererType,
} from '../../../../types/AgGridTypes';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { fetchData } from '../../../../hooks/useGQLQuery';
import unitRateQueries from '../../../../queries/unitrates';
import Loader from '../../../../components/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import {
    useLocation, Link, useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import {
    IServerSideDatasource, IServerSideGetRowsParams,
    ICellRendererParams,
    RowHeightParams,
    ColSpanParams,
} from 'ag-grid-community';
import { PATH_CMFR } from '../../../../Routes/path';
import { useDebounce } from '../../../../hooks/useDebounce';
import { OutletContext } from '../../../../types/CommoditiesListType';
import gqlConfig from '../../../../helper/gqlConfig';
import UnitRateType from '../../../../types/UnitRateType';
import formatRowData from '../../../../helper/rowFormat';
import UnitCostType from '../../../../types/UnitCostType';
import { AgGridReact } from 'ag-grid-react';

interface LocationState {
    unitRateId?: string;
}

interface Props {
    handleReset?: () => void;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>,
    isFetching: boolean,
    selectedCoaLabel: string | undefined,
    isGlobalSearchActive: boolean,
    hideUnusedFlag: boolean,
    setSearchText: React.Dispatch<React.SetStateAction<string>>,
    searchText: string,
    selectedCoaCodeOne: string,
    flagForCoa?: boolean,
}

interface UnitCostData {
    unit_cost_data:UnitCostType[];
}

interface UnitCostResponse {
    unitCostByUnitRateId: UnitCostData;
}

interface UnitRateResponse {
    unitrateListView?:
        {
            data: UnitRateType[];
        }
}

function UnitRateListView(props:Props) {
    const {
        searchText, selectedCoaCodeOne, setSearchText, hideUnusedFlag, isGlobalSearchActive, handleReset, selectedCoaLabel, setIsFetching, isFetching,
        flagForCoa,
    } = props;
    const classes = useStyles();
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const userContext = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const navigate = useNavigate();
    const location = useLocation();
    const { unitRateId } = (location.state || {}) as LocationState;
    const debouncingValue = useDebounce(searchText);
    const graphQLClient = gqlConfig('/list');
    const exchangeRate = displayCurr?.exchange_rate;
    const handleLink = (p: UnitRateType) => {
        navigate(PATH_CMFR.capex.resources, { state: { unitCostId: p?.id, code: p?.code, description: p?.description } });
    };
    const cont: OutletContext = useOutletContext();
    const masterDetailsCache = useRef<{ [key: string]: UnitCostType[] }>({});
    const colFormat = ['lab_rate', 'unit_labour_cost', 'unit_material_cost', 'unit_consequip_cost',
        'unit_cost', 'cost_in_estimate', 'total_unit_cost', 'unit_cost_data'];
    const masterDataColFormat = ['entity_cost', 'labour', 'material', 'equipment', 'p_equipment', 'subcontract', 'total_unit_resource'];
    const loadingRenderer = true;
    const isSearchTextEmpty = searchText.length === 0;
    const [filterFlag, setFilterFlag] = useState<UnitRateType[]>();
    const ref = useRef<AgGridReact>(null);
    const [isPivotActive, setIsPivotActive] = useState<boolean>(false);
    const [valueColsLength, setValueColsLength] = useState<number>(0);

    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const gridCustomCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        const { value, data } = params;
        if (params.value != null) {
            switch (colName) {
            case 'quantity_in_estimate':
            case 'quantity':
            case 'total_quantity':
            case 'labour_hours':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(2), local) : '-';
            case 'unit_man_hours':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(2), local) : '-';
            case 'lab_rate':
            case 'labour':
            case 'material':
            case 'equipment':
            case 'p_equipment':
            case 'subcontract':
            case 'unit_labour_cost':
            case 'unit_material_cost':
            case 'unit_consequip_cost':
            case 'total_unit_cost':
                return (params?.value || params?.value === 0) ? formatCurrency(params.value.toFixed(2)) : '-';
            case 'description':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(data);
                        }}
                    >
                        {value}
                    </Link>
                );
            case 'coa_code':
                if (params.data.shouldStyleApply) {
                    return (
                        <span className={classes.boldText}>
                            {params?.value}
                        </span>
                    );
                }
                return params.value;
            default:
                return colName ? formatCurrency(params?.value) : params?.value;
            }
        }
        if (params.value === null) {
            return '-';
        }
        return params?.value;
    };

    useEffect(() => {
        if (!isPivotActive && valueColsLength) ref?.current?.columnApi?.setValueColumns([]);
        else if (isPivotActive && !ref?.current?.columnApi?.getRowGroupColumns().length) {
            ref?.current?.columnApi?.resetColumnState();
        }
    }, [isPivotActive]);

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params.node.rowIndex === 0 && !unitRateId && !isGlobalSearchActive) {
                    return { component: null };
                }
                return { component: 'agGroupCellRenderer' };
            },
            sortable: false,
            enableValue: false,
            enableRowGroup: false,
        },
        {
            field: 'coa_code',
            headerName: 'COA',
            initialWidth: 150,
            type: 'string',
            initialSort: 'asc',
            enableValue: false,
            cellRendererFramework: isPivotActive ? null : gridCustomCellRenderer,
            colSpan: (params: ColSpanParams) => {
                if ((params?.data as UnitRateType)?.shouldStyleApply) {
                    return 3;
                }
                return 1;
            },
            flex: 0.8,
        },
        {
            field: 'code',
            headerName: 'Code',
            initialWidth: 150,
            type: 'string',
            initialSort: 'asc',
            enableValue: false,
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            initialWidth: 450,
            type: 'string',
            cellRendererFramework: isPivotActive ? null : gridCustomCellRenderer,
            enableValue: false,
            flex: 3.5,
        },
        {
            field: 'unit',
            headerName: 'Unit',
            initialWidth: 120,
            type: 'string',
            enableValue: false,
            flex: 0.6,
        },
        {
            field: 'unit_man_hours',
            headerName: 'Labour Hours',
            initialWidth: 160,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'lab_rate',
            headerName: 'Labour Rate',
            initialWidth: 160,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_labour_cost',
            headerName: 'Labour',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_material_cost',
            headerName: 'Material',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_consequip_cost',
            headerName: 'C.Equip',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_cost',
            headerName: 'Unit Rate',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'quantity_in_estimate',
            headerName: 'Qty',
            initialWidth: 190,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'cost_in_estimate',
            headerName: 'Total Cost',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'total_unit_cost',
            headerName: 'Total Unit Cost',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
    ];

    // This will clear the selection of row.
    useEffect(() => {
        ref?.current?.api?.deselectAll();
    }, [exchangeRate, debouncingValue, isGlobalSearchActive, selectedCoaCodeOne]);

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'entity_code',
                        headerName: 'Code',
                        initialWidth: 90,
                        flex: 0.8,
                    },
                    {
                        field: 'entity_description',
                        headerName: 'Description',
                        initialWidth: 240,
                        flex: 3.2,
                    },
                    {
                        field: 'quantity',
                        headerName: 'Qty',
                        initialWidth: 90,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 0.8,
                    },
                    {
                        field: 'total_quantity',
                        headerName: 'T.Qty',
                        initialWidth: 85,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 0.8,
                    },
                    {
                        field: 'entity_unit',
                        headerName: 'Unit',
                        initialWidth: 60,
                        flex: 0.7,
                    },
                    {
                        field: 'entity_cost',
                        headerName: 'Cost Per Unit',
                        initialWidth: 120,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1.35,
                    },
                    {
                        field: 'labour_hours',
                        headerName: 'Hours',
                        initialWidth: 95,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'labour',
                        headerName: 'Labour',
                        initialWidth: 105,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'material',
                        headerName: 'Material',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'equipment',
                        headerName: 'C.Equip',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'p_equipment',
                        headerName: 'P.Equip',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'subcontract',
                        headerName: 'Sub',
                        initialWidth: 110,
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                        type: 'numericColumn',
                    },
                    {
                        field: 'total_unit_resource',
                        headerName: 'Total',
                        initialWidth: 110,
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                        type: 'numericColumn',
                    },
                ],
                defaultColDef: {
                    // flex: 1,
                    sortable: true,
                    unSortIcon: true,
                    suppressMenu: true,
                    resizable: true,
                },
                getRowHeight: (params: RowHeightParams) => 27,
                suppressLoadingOverlay: true,
                loadingOverlayComponent: null,
                getContextMenuItems: () => ['copy', 'copyWithHeaders', 'export'],
            },

            getDetailRowData(params: CellRendererDetailsType) {
                const unitRateIdParam = params.data.id;
                if (masterDetailsCache.current[unitRateIdParam]) {
                    const cachedData = { unit_cost_data: masterDetailsCache.current[unitRateIdParam] as UnitCostType };
                    const updatedRowData = formatRowData(
                        [cachedData as UnitRateType],
                        colFormat,
                        exchangeRate,
                        undefined,
                        true,
                        masterDataColFormat,
                    );
                    params.successCallback((updatedRowData[0] as UnitCostData)?.unit_cost_data);
                    return;
                }

                const query = unitRateQueries.GET_UNIT_COST_BY_UNIT_RATE_ID(
                    0,
                    1000,
                    userContext?.user?.default_org_id || '',
                    projectCtx?.project?.id || '',
                    projectCtx?.project?.version_id || '',
                    unitRateIdParam,
                );
                setIsFetching(true);
                fetchData(graphQLClient, query, {})
                    .then((response: unknown) => {
                        const data = (response as UnitCostResponse).unitCostByUnitRateId;

                        const updatedRowData = formatRowData(
                            [data as UnitRateType],
                            colFormat,
                            exchangeRate,
                            undefined,
                            true,
                            masterDataColFormat,
                        );

                        const updatedRow = (updatedRowData[0] as UnitCostData).unit_cost_data;
                        masterDetailsCache.current[unitRateIdParam] = updatedRow;

                        params.successCallback(updatedRow);
                        setIsFetching(false);
                    })
                    .catch((err: Error) => {
                        setIsFetching(false);
                        cont.showNotificationBar(err.message.substring(0, 60), 'error');
                    });
            },
        }),
        [projectCtx?.project?.currency, exchangeRate],
    );

    const rowDataCacheRef = useRef<{
        [key: string]: {
            rowData: UnitRateType[],
            exchangeRate: number
        }
    }>({});

    const currentCoaCode = selectedCoaCodeOne || 'default';

    const loadServerSideData = useCallback((params: IServerSideGetRowsParams) => {
        const {
            sortModel, groupKeys, rowGroupCols, valueCols, pivotMode,
        } = params.request;
        setIsPivotActive(() => pivotMode);
        if ((pivotMode && valueCols.length) || (!valueCols.length && !pivotMode)
            || (pivotMode && rowGroupCols.length)) {
            setValueColsLength(() => valueCols.length);
            const startRow = params.request?.startRow;
            const filterModel = params.request.filterModel as unknown;
            const newExchangeRate = displayCurr?.exchange_rate;
            const rowDataCache = rowDataCacheRef.current[currentCoaCode];
            if (filterModel) {
                ref?.current?.api?.deselectAll();
            }
            if (rowDataCache?.exchangeRate && newExchangeRate && rowDataCache.exchangeRate !== newExchangeRate) {
                setIsFetching(true);
                const updatedRowData = formatRowData(rowDataCache.rowData, colFormat, newExchangeRate);
                rowDataCache.exchangeRate = newExchangeRate;
                params.successCallback(updatedRowData as string[], rowDataCache.rowData.length);
                setIsFetching(false);
            } else if (exchangeRate) {
                if (
                    (debouncingValue && !selectedCoaCodeOne)
                    || unitRateId
                    || (!!selectedCoaCodeOne && !debouncingValue && !flagForCoa && isSearchTextEmpty)
                ) {
                    setIsFetching(true);
                    const query = unitRateQueries.GET_UNIT_RATE_LIST_VIEW_DATA(
                        startRow as number,
                        DEFAULT_PAGE_SIZE,
                        userContext?.user?.default_org_id || '',
                        projectCtx?.project?.id || '',
                        projectCtx?.project?.version_id || '',
                        unitRateId || '',
                        isGlobalSearchActive ? false : hideUnusedFlag,
                        debouncingValue,
                        newExchangeRate,
                        JSON.stringify(filterModel) || '',
                        sortModel || [],
                        groupKeys || [],
                        JSON.stringify(rowGroupCols) || '',
                        isGlobalSearchActive || unitRateId ? '' : selectedCoaCodeOne,
                        JSON.stringify(valueCols) || '',
                    );

                    fetchData(graphQLClient, query, {}).then((response: unknown) => {
                        const responseWithType = response as UnitRateResponse;
                        const unitRateResponse = responseWithType.unitrateListView;
                        const rowData = unitRateResponse?.data || [];
                        if (!unitRateId && !isGlobalSearchActive
                            && !groupKeys.length && !rowGroupCols.length && !valueCols.length && selectedCoaCodeOne && !pivotMode
                        ) {
                            let newRow: UnitRateType = {};
                            if (rowGroupCols.length) {
                                const groupingField = rowGroupCols[groupKeys.length].field;
                                newRow = {
                                    [groupingField as string]: selectedCoaLabel,
                                };
                            } else {
                                newRow = {
                                    coa_code: selectedCoaLabel,
                                    shouldStyleApply: true,
                                };
                            }
                            rowData.unshift(newRow);
                        }
                        const lastRow = startRow as number + (rowData?.length || 0);
                        if (unitRateResponse?.data.length) {
                            setFilterFlag(unitRateResponse?.data);
                        }
                        if (!isGlobalSearchActive) {
                            rowDataCacheRef.current[currentCoaCode] = {
                                rowData,
                                exchangeRate: newExchangeRate as number,
                            };
                        }
                        const updatedRowData = formatRowData(rowData, colFormat, newExchangeRate);
                        params.successCallback(updatedRowData as string[], lastRow);
                        if (!updatedRowData.length) {
                            ref?.current?.api?.showNoRowsOverlay();
                        }
                        setIsFetching(false);
                    }).catch((err: Error) => {
                        cont.showNotificationBar(err.message.substring(0, 60), 'error');
                        setIsFetching(false);
                        params.failCallback();
                    });
                }
            }
        } else if (pivotMode || valueCols.length) {
            params.successCallback([], 0);
            ref?.current?.api?.showNoRowsOverlay();
        }
    }, [selectedCoaCodeOne, exchangeRate, debouncingValue, hideUnusedFlag, unitRateId, flagForCoa]);

    const serverSideDatasource: IServerSideDatasource = useMemo(() => ({
        getRows: (params: IServerSideGetRowsParams) => loadServerSideData(params),
    }), [loadServerSideData]);

    return (
        <div>
            <Box className={`${unitRateId ? classes.gridHeight1 : classes.gridHeight2} ${classes.projectListMain}`}>
                { unitRateId && filterFlag && (
                    <Box className={classes.filterDesc}>
                        <span>Data filtered by:</span>
                        <span>
                            {' '}
                            {filterFlag[0]?.code}
                            {' '}
                            -
                            {' '}
                            {filterFlag[0]?.description}
                        </span>
                    </Box>
                )}
                <AgGridComponent
                    gridRef={ref}
                    isAssociation={!!unitRateId}
                    unCheckFirstRow={!unitRateId && !isGlobalSearchActive}
                    columnDefs={columnDefs}
                    datasource={serverSideDatasource}
                    setSearchText={setSearchText}
                    changeSortingValue={changeSortingValue}
                    sortOrder={sortOrder}
                    isRangeSelectable
                    isPinnable
                    masterDetail
                    detailCellRendererParams={detailCellRendererParams}
                    isGroupable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    defaultExpanded={1}
                    moduleName="UnitRateListView"
                    colFormat={colFormat}
                    masterDataColFormat={masterDataColFormat}
                    disableResizable
                    loadingRenderer={loadingRenderer}
                    handleReset={handleReset}
                    unitRateRowBGStyle
                    isMultiFilterRequired={false}
                />
                <Loader loading={isFetching} />
            </Box>
        </div>
    );
}

export default UnitRateListView;
